<template>
  <div :class="`comment-card ${item && item.reply ? 'isReply' : ''}`" v-if="item && item.user" :deep="item.deep">
    <div class="comment-card-wrap">
      <div class="comment-card-content">
        <div class="comment-card-user">
          <figure class="picture" :style="`--picture:url(${getPicture(item)})`"></figure>
        </div>
        <div class="comment-card-message">
          <b class="user">@{{ item.user.user }}:</b>
          <span v-html="cleanHTML(decodeURIComponent(item.comment))"></span>
        </div>
      </div>
      <div class="comment-card-options">
        <a class="comment-card-options-label">{{ getTimeLapse({ date: item.date }) }}</a>
        <a class="comment-card-options-button" @click="setReplyComment">{{ $locale.words["reply"] }}</a>
        <a class="comment-card-options-likes">
          <CommentLikes :comment="item" />
        </a>
      </div>
    </div>
    <div class="comment-card-comments">
      <CommentsBlock :comment="item" />
    </div>
  </div>
  <div v-else>{{ item }}</div>
</template>

<script>
export default {
  components: {
    CommentLikes: () => import("./CommentLikes.vue"),
    CommentsBlock: () => import("./CommentsBlock.vue"),
  },
  props: ["item"],
  methods: {
    getPicture: function(item) {
      return item?.user?.picture;
    },
    setReplyComment: function() {
      this.$store.commit("setReplyComment", this.item);
      const messagebox = document.querySelector("#comment-data [name=message]");
      messagebox.focus();
    },
  },
  computed: {
    comments: function() {
      return this.$store.state.comments.comments;
    },
  },
};
</script>

<style lang="scss">
.comment-card {
  &-wrap {
    padding: $mpadding;
  }
  &-content {
    @include Flex(inherit, flex-start, center);
    gap: $mpadding;
    position: relative;
  }
  &-user {
    .picture {
      @include Avatar($mpadding * 3);
      border: solid 2px rgba(9, 30, 66, 00);
    }
  }
  &-message {
    word-break: break-word;
    background-color: rgba(9, 30, 66, 0.04);
    padding: $mpadding;
    border-radius: $mradius;
    min-width: 230px;
    .user {
      margin: 0 $mpadding/2 0 0;
    }
  }
  &-options {
    padding: $mpadding/2 0 0 $mpadding * 4;
  }
  &-options {
    @include Flex(inherit, inherit, center);
    gap: $mpadding;
    user-select: none;
    &-button {
      cursor: pointer;
    }
    &-label {
      font-size: 90%;
      opacity: 0.7;
    }
  }
  &.isReply &-user {
    .picture {
      @include Avatar($mpadding * 2);
    }
  }
  &.isReply &-options {
    padding: $mpadding/2 0 0 $mpadding * 3;
  }
  &-wrap:hover > &-options &-options-button {
    font-weight: bold;
  }
  &-comments & {
    position: relative;
  }
  &[deep="1"],
  &[deep="2"] {
    padding: 0 0 0 $mpadding * 5;
  }
  &:hover &-wrap,
  &-wrap:hover,
  &-wrap:hover + &-comments {
    position: relative;
    /*Mark all replies*/
    /* .picture:first-child {
      border: solid 2px;
      border-color: $primary_color;
    } */
  }
  &:hover > &-wrap {
    /* background-color: $gray2; */
    .picture:first-child {
      border: solid 2px;
      border-color: $primary_color;
    }
  }

  @media (min-width: $tablet_width) {
    &[deep="3"] {
      padding: 0 0 0 $mpadding * 5;
    }
  }

  @media (min-width: $pc_width) {
    &[deep="4"],
    &[deep="5"],
    &[deep="6"] {
      padding: 0 0 0 $mpadding * 5;
    }
  }
}
</style>
